<template>
  <base-section id="about-our-product">
    <!--<v-container>-->
      <!--<v-row-->
        <!--align="center"-->
        <!--justify="center"-->
      <!--&gt;-->
        <!--<base-img-->
          <!--:src="require('@/assets/logo.png')"-->
          <!--max-width="160"-->
          <!--width="100%"-->
        <!--/>-->
      <!--</v-row>-->
    <!--</v-container>-->

    <base-section-heading
      color="grey lighten-2"
      title="OlapDB，所有企业都玩得转的数据仓库"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: '使用简单，持续为您带来经济效益',
          subtitle: 'Best Productivity',
          text: 'OlapDB数据仓库使用非常简单，只需简单的几步，即可完成您的业务分析。基于OlapDB的商务智能能够加快您企业的决策。',
          callout: '01',
        },
        {
          title: '部署简单，除了HBase，不需要其他前置条件',
          subtitle: 'Special Offers',
          text: 'OlapDB数据仓库除了HBase外，不需要依赖其他数据组件，即使没有任何大数据使用经验的企业也可以很容易的部署使用。',
          callout: '02',
        },
        {
          title: '运营成本低，为您节省人员投入',
          subtitle: 'Income Flow',
          text: 'OlapDB数据仓库设计简洁高效，降低了系统的复杂度，环境依赖小，节省了人员投入，系统运营成本低。',
          callout: '03',
        },
      ],
    }),
  }
</script>
